body {
  margin: 0;
}

.buttons {
  position: relative;
  color: #fff;
  max-width: 305px;
}

.buttons svg {
  padding: 5px 12px;
  border-radius: 8px;
  width: 150px;
  height: 46px;
  background-color: #fff;
}

.buttons .right {
  padding-left: 35px;
}

.swiper-slide-caption.pull-left.content-size {
  max-width: 500px;
}

#mobile-app-header {
  background-image: url("../images/mobile/shapka.png");
}

#mobile-app-header .content-holder {
  position: relative;
  horiz-align: center;
}

#mobile-app-header .content-holder .info-block {
  color: #fff;
  padding-top: 45px;
}

@media screen and (max-width: 766px) {
  #mobile-app-header .content-holder .info-block {
    padding-left: 0;
    margin: 0 auto;
    display: block;
  }
}

#mobile-app-header .content-holder .info-block h3 {
  color: white;
  padding-left: 0;
}

@media screen and (max-width: 766px) {
  #mobile-app-header .content-holder .info-block h3 {
    margin-top: 200px;
  }
}

#mobile-app-header .content-holder .info-block p {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 0;
  width: 300px;
}

#mobile-app-section div.white {
  background-color: #ffffff;
  min-height: 450px;
  position: relative;
}

#mobile-app-section div.white .info-block {
  padding-left: 350px;
}

@media screen and (max-width: 766px) {
  #mobile-app-section div.white .info-block {
    padding-left: 0;
    margin: 0 auto;
    margin-top: 220px;
  }
}

#mobile-app-section .first-block .info-block img {
  width: 63px;
  height: 137px;
}

@media screen and (max-width: 766px) {
  #mobile-app-section .first-block .info-block img {
    width: 63px;
    height: 137px;
  }
}

@media screen and (max-width: 766px) {
  #mobile-app-section .first-block .info-block {
    margin-top: 50px !important;
  }
}

#mobile-app-section .first-block .info-block h3 {
  padding-left: 90px;
  width: 500px;
}

@media screen and (max-width: 766px) {
  #mobile-app-section .first-block .info-block h3 {
    padding-left: 50px;
    width: 300px;
  }
}

#mobile-app-section .first-block .info-block p {
  padding-left: 90px;
  width: 520px;
}

@media screen and (max-width: 766px) {
  #mobile-app-section .first-block .info-block p {
    padding-left: 50px;
    width: 350px;
  }
}

#mobile-app-section div.gray {
  background-color: #e6e6e6;
  min-height: 450px;
  position: relative;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

@media screen and (max-width: 766px) {
  #mobile-app-section div.gray {
    padding-top: 220px;
  }
}

@media screen and (max-width: 766px) {
  #mobile-app-section div.gray .info-block {
    margin-top: 0;
  }
}

#mobile-app-section div.gray .app-image {
  right: 0;
}

#mobile-app-section .content-holder {
  max-width: 970px;
  margin: 0 auto;
  padding: 0 15px 0 15px;
  position: relative;
}

#mobile-app-section .content-holder .app-image {
  position: absolute;
  height: 515px;
  width: 263px;
  top: 90px;
  z-index: 1;
}

@media screen and (max-width: 766px) {
  #mobile-app-section .content-holder .app-image {
    position: absolute;
    margin: auto;
    top: 150px;
    left: 0;
    right: 0;
  }
}

#mobile-app-section .info-block {
  max-width: 500px;
  padding-top: 21%;
  color: #666666;
}

@media screen and (max-width: 766px) {
  #mobile-app-section .info-block {
    max-width: 435px;
    padding-top: 15px;
    padding-left: 0;
    margin: 0 auto;
    margin-top: 220px;
  }
}

#mobile-app-section .info-block img {
  width: 98px;
  height: 134px;
  position: absolute;
  z-index: 2;
}

@media screen and (max-width: 766px) {
  #mobile-app-section .info-block img {
    width: 94px;
    height: 126px;
  }
}

#mobile-app-section .info-block h3 {
  position: relative;
  z-index: 10;
  font-size: 30px;
  text-transform: uppercase;
  font-family: HelveticaNeueCyr, sans-serif;
  font-weight: 600;
  padding-left: 75px;
  line-height: 1.3;
  margin-bottom: 0;
  width: 500px;
}

@media screen and (max-width: 766px) {
  #mobile-app-section .info-block h3 {
    font-size: 21px;
    padding-left: 50px;
    margin-top: 5px;
    width: 300px;
  }
}

#mobile-app-section .info-block p {
  position: relative;
  z-index: 10;
  font-family: HelveticaNeueCyr, sans-serif;
  line-height: 1.3;
  font-size: 15px;
  padding-left: 75px;
  width: 520px;
}

@media screen and (max-width: 766px) {
  #mobile-app-section .info-block p {
    padding-left: 50px;
    font-size: 13px;
    width: 350px;
  }
}

#mobile-app-section .footer {
  background-color: #3bb44a;
  min-height: 320px;
}

#mobile-app-section .footer .info-block {
  position: relative;
  margin-left: 433px;
  color: #fff;
  padding-top: 45px;
  max-width: 305px;
}

@media screen and (max-width: 766px) {
  #mobile-app-section .footer .info-block {
    padding-left: 0;
    margin: 0 auto;
    display: block;
  }
}

#mobile-app-section .footer .info-block h3 {
  color: white;
  padding-left: 0;
}

@media screen and (max-width: 766px) {
  #mobile-app-section .footer .info-block h3 {
    margin-top: 200px;
  }
}

#mobile-app-section .footer .info-block p {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 0;
  width: 300px;
}

#mobile-app-section .footer .button-holder {
  margin-left: 433px;
}

@media screen and (max-width: 766px) {
  #mobile-app-section .footer .button-holder {
    max-width: 320px;
    padding-left: 0;
    margin: 0 auto;
    margin-top: 20px;
    padding-bottom: 30px;
  }
  #mobile-app-section .footer .button-holder svg {
    width: 63px !important;
    height: 21px !important;
  }
}

#mobile-app-section .footer .button-holder svg {
  padding: 5px 10px;
  border-radius: 8px;
  margin-right: 5px;
  width: 150px;
  height: 46px;
  background-color: #fff;
}
